import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Core intellectual property`}</h4>
    <p>{`Core intellectual property (IP) consists of reusable, modular design blocks that chip design firms license for use in their designs. U.S. and U.K. vendors dominate the core IP market; some firms specialize exclusively on core IP, while others combine their offerings with EDA tools. The company ARM is the top core IP vendor, providing an instruction set architecture (ISA) and associated core IP underpinning most of the world's smartphone processors.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      